// Data structure adapted from: https://github.com/davepagurek/Axis/blob/master/public/population.js
export default function Stickman(x, y) {
  return {
    name: "root",
    pos: { x, y },
    root: true,
    points: [
      {
        name: "body",
        pos: { x: 0, y: -80 },
        type: "line",
        points: [
          {
            name: "head",
            pos: { x: 0, y: -20 },
            radius: 20,
            type: "circle",
          },
          {
            name: "armTopLeft",
            pos: { x: -50, y: 25 },
            type: "line",
            points: [
              {
                name: "armBottomLeft",
                pos: { x: -25, y: 50 },
                type: "line",
              },
            ],
          },
          {
            name: "armTopRight",
            pos: { x: 50, y: 25 },
            type: "line",
            points: [
              {
                name: "armBottomRight",
                pos: { x: 25, y: 50 },
                type: "line",
              },
            ],
          },
        ],
      },
      {
        name: "legTopLeft",
        pos: { x: -20, y: 80 },
        type: "line",
        points: [
          {
            name: "legBottomLeft",
            pos: { x: -10, y: 80 },
            type: "line",
          },
        ],
      },
      {
        name: "legTopRight",
        pos: { x: 20, y: 80 },
        type: "line",
        points: [
          {
            name: "legBottomRight",
            pos: { x: 10, y: 80 },
            type: "line",
          },
        ],
      },
    ],
  };
}
