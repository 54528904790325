import { h } from "superfine";
import svgUrl from "./feather-sprite.svg";

export function Icon({ name, size, css }) {
  return h(
    "svg",
    {
      width: size,
      height: size,
      class: css,
      fill: "none",
      stroke: "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      xmlns: "http://www.w3.org/2000/svg",
    },
    [h("use", { href: `${svgUrl}#${name}` })]
  );
}
