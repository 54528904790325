import { h } from "superfine";
import { mousedown, deleteObject } from "./index.js";

export default function RenderObject(object, root, showEdit) {
  if (!object) return null;

  if (Array.isArray(object)) {
    return object.map((o) => RenderObject(o, root, showEdit));
  }

  let pos = root
    ? {
        x: object.pos.x + root.x,
        y: object.pos.y + root.y,
      }
    : object.pos;

  let handlePos =
    object.type == "rect"
      ? {
          x: pos.x + object.width / 2,
          y: pos.y + object.height / 2,
        }
      : pos;

  return [
    object.type == "line" && line(root, pos),
    object.type == "circle" && circle(pos, object.radius),
    object.type == "rect" && rect(pos, object.width, object.height),
    object.type == "cathead" && catHead(pos, object.radius),
    Array.isArray(object.points) &&
      object.points.map((p) => RenderObject(p, pos, showEdit)),
    showEdit && handle(handlePos, object.root ? "orange" : "red", object),
    object.type == "circle" && showEdit && handleRadius(pos, "green", object),
    object.type == "rect" &&
      showEdit &&
      handleWidthHeight(pos, "green", object),
    showEdit && object.root && deleteButton(object, handlePos),
  ];
}

function line(start, end) {
  return h("line", {
    x1: start.x,
    y1: start.y,
    x2: end.x,
    y2: end.y,
    stroke: "black",
    ["stroke-width"]: "10px",
    ["stroke-linecap"]: "round",
    fill: "none",
  });
}

function circle(pos, radius) {
  return h("circle", {
    cx: pos.x,
    cy: pos.y,
    r: radius,
    stroke: "black",
    ["stroke-width"]: "10px",
    fill: "none",
  });
}

function rect(pos, width, height) {
  return h("rect", {
    x: pos.x,
    y: pos.y,
    width,
    height,
    stroke: "black",
    ["stroke-width"]: "10px",
    fill: "none",
  });
}

function handle(pos, color, objPos) {
  return h("circle", {
    cx: pos.x,
    cy: pos.y,
    r: 8,
    fill: color,
    ontouchstart: mousedown(objPos),
    onmousedown: mousedown(objPos),
  });
}

function handleRadius(pos, color, obj) {
  return h("circle", {
    cx: pos.x,
    cy: pos.y - obj.radius,
    r: 8,
    fill: color,
    ontouchstart: mousedown(obj, "radius"),
    onmousedown: mousedown(obj, "radius"),
  });
}

function handleWidthHeight(pos, color, obj) {
  return [
    h("circle", {
      cx: pos.x,
      cy: pos.y + obj.height / 2,
      r: 8,
      fill: color,
      ontouchstart: mousedown(obj, "width"),
      onmousedown: mousedown(obj, "width"),
    }),
    h("circle", {
      cx: pos.x + obj.width / 2,
      cy: pos.y,
      r: 8,
      fill: color,
      ontouchstart: mousedown(obj, "height"),
      onmousedown: mousedown(obj, "height"),
    }),
  ];
}

function deleteButton(obj, handlePos) {
  return h(
    "svg",
    {
      xmlns: "http://www.w3.org/2000/svg",
      width: "24",
      height: "24",
      x: handlePos.x + 12,
      y: handlePos.y - 12,
      viewBox: "0 0 24 24",
      fill: "none",
      stroke: "red",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      onclick: deleteObject(obj),
    },
    [
      h("circle", {
        cx: "12",
        cy: "12",
        r: "10",
        fill: "white",
      }),
      h("line", {
        x1: "15",
        y1: "9",
        x2: "9",
        y2: "15",
      }),
      h("line", {
        x1: "9",
        y1: "9",
        x2: "15",
        y2: "15",
      }),
    ]
  );
}

function catHead(pos, radius) {
  return h(
    "svg",
    {
      viewBox: "0 0 71.266121 66.757812",
      width: radius * 2,
      height: radius * 2,
      x: pos.x - radius,
      y: pos.y - radius,
    },
    [
      h(
        "g",
        {
          fill: "none",
          stroke: "#000",
          transform: "translate(-36.200789 -85.122671)",
        },
        [
          h("circle", {
            cx: "71.834373",
            cy: "125.15312",
            r: "23.415625",
            "stroke-width": "10",
          }),
          h("path", {
            "stroke-width": "5",
            d: "M50.799999 115.49583l-3.175-27.516663L66.410415 101.7375M93.133338 115.49583l3.175-27.51666-18.785416 13.75833",
          }),
          h("path", {
            "stroke-width": "3",
            d: "M62.177083 133.22292l-25.929167-1.85209M62.177083 136.39792L38.1 140.63126",
          }),
          h("path", {
            "stroke-linecap": "round",
            "stroke-width": "3",
            d: "M63.499998 139.30833l-22.489583 11.90625",
          }),
          h("path", {
            "stroke-width": "3",
            d: "M81.490566 133.12813l25.929214-1.85209M81.490566 136.30313l24.077114 4.23334M80.167653 139.21354l22.489597 11.90625",
          }),
        ]
      ),
    ]
  );
}
