import { h, patch } from "superfine";
import cc from "classcat";
import Stickman from "./objects/stickman.js";
import Ball from "./objects/ball.js";
import Box from "./objects/box.js";
import RenderObject from "./render.js";
import Kitty from "./objects/kitty.js";
import { Icon } from "./components/Icon.js";

function defaultState() {
  return {
    selected: null,
    selectedProperty: null,
    animating: false,
    frameLength: 250,
    lastX: 0,
    lastY: 0,
    width: 720,
    height: 480,
    frames: [[Stickman(300, 240), Kitty(500, 240)]],
    currentFrame: 0,
  };
}

const localStorageKey = "animation";

function clearStorage() {
  localStorage.removeItem(localStorageKey);
  state = defaultState();
  render(state);
  return null;
}

function save(obj) {
  localStorage.setItem(localStorageKey, JSON.stringify(obj));
}

function restore() {
  let strVal = localStorage.getItem(localStorageKey);
  return JSON.parse(strVal);
}

let found = restore() || {};

// Single, mutable state object.
let state = {
  ...defaultState(),
  ...found,
};

function handleMousemove(event) {
  if (state.selected != null) {
    event.preventDefault();
    let newX = state.lastX;
    let newY = state.lastY;
    if (event.type == "touchmove") {
      newX = event.touches[0].clientX;
      newY = event.touches[0].clientY;
    } else {
      newX = event.clientX;
      newY = event.clientY;
    }

    const dx = newX - state.lastX;
    const dy = newY - state.lastY;

    if (state.selectedProperty == null) {
      state.selected.pos.x += dx;
      state.selected.pos.y += dy;
    } else if (state.selectedProperty == "radius") {
      state.selected.radius -= dy;
    } else if (state.selectedProperty == "width") {
      state.selected.width -= dx;
      state.selected.pos.x += dx;
    } else if (state.selectedProperty == "height") {
      state.selected.height -= dy;
      state.selected.pos.y += dy;
    }

    state.lastX = newX;
    state.lastY = newY;

    render(state);
  }
}

export function mousedown(object, property) {
  return function mouseDownHandler(event) {
    state.selected = object;
    state.selectedProperty = property;

    if (event.type == "touchstart") {
      event.preventDefault();
      state.lastX = event.touches[0].clientX;
      state.lastY = event.touches[0].clientY;
    } else {
      state.lastX = event.clientX;
      state.lastY = event.clientY;
    }
  };
}

function mouseUpHandler() {
  state.selected = null;
  state.selectedProperty = null;
}

function selectFrame(index) {
  state.currentFrame = index;
  render(state);
}
function nextFrame() {
  return state.currentFrame === state.frames.length - 1
    ? 0
    : state.currentFrame + 1;
}

function addFrame() {
  let clone = JSON.parse(JSON.stringify(state.frames[state.frames.length - 1]));
  state.frames.push(clone);
  selectFrame(state.frames.length - 1);
  render(state);
  let container = document.getElementById("frames-container");
  container.scrollLeft = container.scrollWidth;
}

function addKitty() {
  addObjectToCurrentFrame(Kitty(state.width / 2, state.height / 2));
}

function addStickman() {
  addObjectToCurrentFrame(Stickman(state.width / 2, state.height / 2));
}

function addObjectToCurrentFrame(object) {
  let current = state.frames[state.currentFrame];
  if (Array.isArray(current)) {
    current.push(object);
  } else {
    state.frames[state.currentFrame] = [current, object];
  }
  render(state);
}

function addBall() {
  addObjectToCurrentFrame(Ball(state.width / 2, state.height / 2, 40));
}
function addBox() {
  addObjectToCurrentFrame(Box(state.width / 2, state.height / 2, 40, 40));
}

export function deleteObject(object) {
  return function () {
    state.frames[state.currentFrame] = state.frames[state.currentFrame].filter(
      (o) => o !== object
    );
    render(state);
  };
}

function deleteFrame(index) {
  state.frames = state.frames.filter((f, i) => i !== index);
  if (state.currentFrame > state.frames.length - 1) {
    state.currentFrame = state.frames.length - 1;
  }
  render(state);
}

function changFrameLength(event) {
  state.frameLength = event.target.value;
  if (state.animating) {
    startAnimation();
  }
  render(state);
}

let interval;
function startAnimation() {
  if (interval) {
    clearInterval(interval);
  }
  state.animating = true;
  selectFrame(0);
  interval = setInterval(() => {
    selectFrame(nextFrame());
  }, state.frameLength);
}
function stopAnimation() {
  clearInterval(interval);
  interval = null;
  state.animating = false;
  render(state);
}

const header = h(
  "header",
  {
    class:
      "bg-gray-800 text-green-300 flex-none flex justify-between items-center border border-current gap-2",
  },
  [
    h("h1", { class: "pl-4 text-lg font-bold font-mono" }, [
      "Animating Rocks!",
      h("span", { class: "text-yellow-300" }, "[Beta]"),
    ]),
    h("div", {}, [
      h(
        "button",
        {
          class:
            "inline-flex items-center border py-1 px-2 border-transparent hover:border-current hover:bg-green-900",
          onclick: () => save(state),
          title: "Save to browser",
        },
        [
          Icon({ name: "save", size: 16 }),
          h("span", { class: "hidden sm:inline ml-2" }, "Save"),
        ]
      ),
      h(
        "button",
        {
          class:
            "inline-flex items-center border py-1 px-2 border-transparent hover:border-current hover:bg-green-900",
          onclick: () => clearStorage(),
          title: "Delete Saved Animation",
        },
        [
          Icon({ name: "trash-2", size: 16 }),
          h("span", { class: "hidden sm:inline ml-2" }, "Delete"),
        ]
      ),
    ]),
  ]
);

const footer = h(
  "footer",
  {
    class:
      "bg-gray-800 text-green-200 px-4 py-2 flex-none text-center border-t border-current text-sm",
  },
  [
    h("p", { class: "" }, [
      "© " +
        new Date().getFullYear() +
        " Skater Dad Software LLC • Developed by Mike Montoya • ",
      h(
        "a",
        { href: "https://twitter.com/SkaterDadCodes", class: "text-blue-400" },
        [
          Icon({ name: "twitter", size: "14", css: "inline-block mr-1" }),
          "@SkaterDadCodes",
        ]
      ),
      " • ",
      h("a", { href: "https://github.com/SkaterDad", class: "text-white" }, [
        Icon({ name: "github", size: "14", css: "inline-block mr-1" }),
        "SkaterDad",
      ]),
    ]),
  ]
);

function deleteFrameButton(frameIndex) {
  return h(
    "button",
    {
      class:
        "absolute bottom-0 left-0 text-red-500 border border-red-500 rounded-full p-1 ml-1 mb-1 hover:bg-red-200",
      onclick: () => deleteFrame(frameIndex),
      title: "delete frame " + frameIndex,
    },
    [Icon({ name: "trash", size: 16, css: "" })]
  );
}

function frameIndexLabel(frameIndex) {
  return h(
    "span",
    {
      class:
        "absolute top-0 left-0 text-black border border-black rounded p-1 ml-1 mt-1 text-xs bg-white",
    },
    frameIndex.toString()
  );
}

const addFrameCss =
  "flex-none flex flex-col items-center justify-center w-12 h-12 sm:w-16 sm:h-16 bg-green-300 text-black hover:bg-green-200 border-2 border-gray-800 hover:border-green-500";

function FramesSection(state) {
  return h("section", { class: "bg-gray-800 flex" }, [
    h(
      "button",
      {
        class: addFrameCss,
        onclick: addFrame,
        title: "Add next frame",
      },
      Icon({ name: "plus", size: "16", css: "" }),
      h("span", { class: "block" }, "Frame")
    ),
    h(
      "div",
      {
        id: "frames-container",
        class:
          "flex-auto overflow-x-auto whitespace-no-wrap max-h-24 sm:max-h-32",
      },
      [
        state.frames.map((frame, index) =>
          h("div", { class: "inline-block relative" }, [
            h(
              "button",
              {
                onclick: () => selectFrame(index),
                title: "select frame " + index,
              },
              h(
                "svg",
                {
                  class: cc([
                    "inline-block bg-white border-4 hover:bg-green-200",
                    "h-16 w-24",
                    state.currentFrame == index
                      ? "border-green-500"
                      : "border-gray-200 border-dashed hover:border-green-300",
                  ]),

                  viewBox: `0 0 ${state.width} ${state.height}`,
                },
                RenderObject(frame)
              )
            ),
            frameIndexLabel(index),
            state.frames.length > 1 && deleteFrameButton(index),
          ])
        ),
      ]
    ),
  ]);
}

const addObjectButtonCss =
  "flex flex-col items-center justify-center w-12 h-12 sm:w-16 sm:h-16 bg-green-300 text-black hover:bg-green-200 border-2 border-gray-800 hover:border-green-500";

function Tools(state) {
  return h(
    "div",
    {
      class:
        "sm:bg-gray-800 flex-none flex mt-1 sm:mt-0 ml-1 sm:ml-0 sm:flex-col gap-1 absolute sm:block shadow-md",
    },
    [
      h(
        "button",
        {
          class: addObjectButtonCss,
          onclick: addStickman,
        },
        [Icon({ name: "plus", size: "16" }), h("span", null, "Person")]
      ),
      h(
        "button",
        {
          class: addObjectButtonCss,
          onclick: addKitty,
        },
        [Icon({ name: "plus", size: "16" }), h("span", null, "Cat")]
      ),
      h(
        "button",
        {
          class: addObjectButtonCss,
          onclick: addBall,
        },
        [Icon({ name: "plus", size: "16" }), h("span", null, "Ball")]
      ),
      h(
        "button",
        {
          class: addObjectButtonCss,
          onclick: addBox,
        },
        [Icon({ name: "plus", size: "16" }), h("span", null, "Box")]
      ),
    ]
  );
}

function AnimationCanvas(state) {
  return h(
    "svg",
    {
      class: "flex-none bg-white border-2 border-black shadow-md mx-auto",
      height: state.height,
      width: state.width,
      viewbox: `0 0 ${state.width} ${state.height}`,
      onmouseup: mouseUpHandler,
      ontouchend: mouseUpHandler,
      onmousemove: handleMousemove,
      ontouchmove: handleMousemove,
    },
    [
      !state.animating &&
        state.currentFrame > 1 &&
        h(
          "g",
          { class: "opacity-[.1]" },
          RenderObject(state.frames[state.currentFrame - 2])
        ),
      !state.animating &&
        state.currentFrame > 0 &&
        h(
          "g",
          { class: "opacity-25" },
          RenderObject(state.frames[state.currentFrame - 1])
        ),
      RenderObject(state.frames[state.currentFrame], null, !state.animating),
    ]
  );
}

function AnimationControls(state) {
  return h(
    "section",
    { class: "flex items-center bg-gray-800 text-green-300" },
    [
      h("div", { class: "flex-none flex" }, [
        h("h3", { class: "sr-only" }, "Animation Controls!"),
        h(
          "button",
          {
            class: addObjectButtonCss,
            onclick: startAnimation,
            title: "Play",
          },
          [Icon({ name: "play", size: 24, css: "h-6 w-6 sm:h-8 sm:w-8" })]
        ),
        h(
          "button",
          {
            class: addObjectButtonCss,
            onclick: stopAnimation,
            title: "Stop",
          },
          [Icon({ name: "square", size: 24, css: "h-6 w-6 sm:h-8 sm:w-8" })]
        ),
      ]),
      h("div", { class: "flex-auto px-2" }, [
        h("label", {}, [
          h(
            "h3",
            { class: "mb-4" },
            `Frame length: ${state.frameLength} ms (${(
              1000 / state.frameLength
            ).toFixed(2)} frames/sec)`
          ),
          h("div", { class: "" }, [
            h("input", {
              class: "slider",
              type: "range",
              min: 20,
              max: 500,
              step: 10,
              value: state.frameLength,
              oninput: changFrameLength,
            }),
          ]),
        ]),
      ]),
    ]
  );
}

function ApplicationView(state) {
  return h(
    "div",
    { class: "flex flex-col min-h-screen max-h-screen text-sm sm:text-base" },
    [
      header,
      h("main", { class: "flex-auto flex flex-col overflow-auto" }, [
        h("section", { class: "w-full flex flex-auto overflow-auto" }, [
          Tools(state),
          h("div", { class: "flex-auto overflow-x-auto p-4" }, [
            AnimationCanvas(state),
          ]),
        ]),
        FramesSection(state),
        AnimationControls(state),
      ]),
      footer,
    ]
  );
}

const render = (state) => {
  patch(document.getElementById("app"), ApplicationView(state));
};

render(state);
