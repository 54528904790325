export default function Ball(x, y, w, h) {
  return {
    name: "box",
    pos: { x, y },
    width: w,
    height: h,
    type: "rect",
    root: true,
  };
}
