export default function Kitty(x, y) {
  return {
    name: "root",
    pos: { x, y },
    root: true,
    points: [
      {
        name: "body",
        pos: { x: 80, y: 0 },
        type: "line",
        points: [
          {
            name: "head",
            pos: { x: 0, y: -30 },
            radius: 30,
            type: "cathead",
          },
          {
            name: "armTopLeft",
            pos: { x: -20, y: 60 },
            type: "line",
          },
          {
            name: "armTopRight",
            pos: { x: 20, y: 60 },
            type: "line",
          },
        ],
      },
      { name: "tail", pos: { x: -50, y: -40 }, type: "line" },
      {
        name: "legTopLeft",
        pos: { x: -20, y: 60 },
        type: "line",
      },
      {
        name: "legTopRight",
        pos: { x: 20, y: 60 },
        type: "line",
      },
    ],
  };
}
